import JsEncrypt from 'jsencrypt'
// 解密
export function sendEncryCode (params) {
  const jse = new JsEncrypt()
  const privKey = 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCSF846DO344/YLrddGjxBPGZbgdiP97tRkF/+W9Mx5WLshMmUzNOKtlReWW/KcNi+z+QzK8oQ0CrTLXtCDopxme6HDAjn6yAJvxHYw2ulxfGT/Jh1tMs1Z9c1hxyuFdzsNJDiAyd8G521jTPr/Xnq66JQzCur8TiiiNDvPrl+DEKNPWk4rzYJB4oFcpZn/nSBzctmSPO+WYQziEvov6ECvoI1oCLWxRWhlRSzdWOos9BHJlIQ6AnvyyTvbTSvdDZoIOxW4yPlLklQnCzLq79CV7CPjdTjjatlwsFgkxa08Vz64+PNED4/cYkTKhM52D3vnEKeDYx8SF0hfvNuPVDL7AgMBAAECggEAVixGj8VKpXHJkxACmHMQuqescFAfYMx8aWIid+eDqNwm3Sj9upuqTZtQ43bu/yeTMcRowFkWlB/wjrkpo0vKl0S7oOxySLOrhEPNN3oZEsC+TiYsW1oI+NRdRhyWVk6C/Xhs/8KTlF30Dwu8Td9r404HBKie/R4ZJ7xJv3HqksLaL14yfxdDlFPFLCVlWOkd/DeqgwgZ/BqMR/akzEcBFWYUUFUUFcUuDBZjEoEfaJ4vkX9F9H+8ZmobHV/dlwJXCLQa5+JJnv4faDbX+zjlYL617iOvBa6talGqtnb+X0Te5SdrUHYOhkvdme/klsOhIM7romTHB1uHIHa2qbB2YQKBgQDe3uGbuXRVd6C1TSeW8wYfcKSre+PKZH6CQOP4hDnM96Tz4Skp1/JT+mcHIh+zvUxZp7UlZoJ6CvjhzbE+Kl68uDnfBJF+FLnRQn1dveEUPSifREVTmjTnrAH5q6DdtBovED3dApJhNdU/qoQvYmzuhraWX7wFaPUsxnVqQdsqcQKBgQCnzzpjaDYLrKcfT4G15ZZ5j9m0Z1lDrsrFeO76BbhdAFnI3FNLpXMvo+Bsppf1iEKFoDOSDuJ4GaMVpCKYGS8m/F7G2kYo4iXMTJv6DH1bp//gUorpftBgeVun3igHu/owCRw/M9uVd38bbi0KZOIRYG5UEbkreVDzeVjg3doyKwKBgQDXMR9njB6+s15SEGhMgOUFkG7/QQVGE9hvDhbQi2N//BGihWKIi4DpAxtdsN4pX3sMCjmHHj6XdmavKDueSazlzX/bTX7p3Ts5yVhhHgjo0NCbT4Ec4wV4OAZEOyp9RvwNO0JwVp5eDGuWKJ+vXPrPKPW9pjxWgOxSSmFusp3akQKBgDOLyTCt2yQc5b0NbBeWrXErycDLmjTbJTghXK76QNZ4fBvh6cgry0UP/qkBR15Je72WJyEvZ0ti/KByKZo06jH8hZRpifGW/etSgPr/CSa1ZTjfwgHVTWbFAG2BJSSzDjfhJIcZg9LWwPmJD1bJBFQ4Hcu/IfUDr+UKGk4Ad7y/AoGBAM6+wj1UuNW8YBICmLF8piK8MhAp0/kNRvWoUTkaOtoRsKWj5gh5bSPULwxsfCICIvATDA75/zrtGTqOF/3QauYiaV9mLzrZHV7ZJzV3G9adHVMDfQp5fJZT0uml97cSZCt8+WgCCBfR16L7TU8DfHhzxswjKuu4Um98BzexIKLu'
  jse.setPrivateKey(privKey)
  const data = jse.decrypt(params) // 解密
  return data
}
export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

// 时间戳转换转换日期格式
export function format (date) {
  // console.log(date)
  date = new Date(+new Date(date) - 1000 * 24 * 60 * 60)
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = (date.getDate() - 1 < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  // const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  // const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return Y + M + D + '16:00:00'
}
// 日期格式转时间戳
export function formatBack (T) {
  const date = new Date(T)
  return date.getTime()
}

// ute时间转北京
export function formatToBeijing (date) {
	if (!date || date === 'undefined') {
		return ''
	}
  // console.log(date)
  // return moment(date).format('YYYY-MM-DD H:mm:ss')
  // var time = new Date(date.replace(/-/g, "/"))
  date = date.replace('T', ' ')
  date = date.replace(/-/g, '/')
  date = date.substring(0, 19)
  var timestamp = +new Date(date)
  timestamp = timestamp.toString().substring(0, 10)
  // 转数字格式
  timestamp = +timestamp
  timestamp = timestamp + 8 * 60 * 60
  // console.log(timestamp)
  // var time = new Date(date)
  var time = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = time.getFullYear() + '-'
  const M =
    (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-'
  const D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate()) + ' '
  const h = (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':'
  const m = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':'
  const s = (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds())
  return Y + M + D + h + m + s
}

// 导出Excel
const XLSX = require('xlsx') // 使用import有的属性无法找到
export function exportExcel (data, filename) {
    const excel = XLSX.utils.book_new() // 初始化一个excel文件
    const excelData = XLSX.utils.aoa_to_sheet(data) // 传入数据 , 到excel
    // 将文档插入文件并定义名称
    XLSX.utils.book_append_sheet(excel, excelData, filename)
    // 执行下载
    XLSX.writeFile(excel, filename || new Date().getTime() + '.xlsx')
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}
