import router, { resetRouter } from './router'
// import router from './router'
import versionTood from '@/utils/versionUpdate'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar  网页加载进度条插件
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'

NProgress.configure({ showSpinner: false }) // NProgress Configuration 显示右上角螺旋加载提示

const allowList = ['login', 'register', 'registerResult', 'alipay', 'webHome', 'KaiTongTuanGou', 'ManYiFu'] // no redirect allowList 免登录路径
const loginRoutePath = '/user/login' // 登录页路由
const defaultRoutePath = '/'

// 路由守卫
router.beforeEach((to, from, next) => {
  versionTood.isNewVersion()
  NProgress.start() // start progress bar 开启进度条
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  /* 判断 token */
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    // 如有token保存不可跳转登录页
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else if (to.path === '/notice/kaitongtuangou' || to.path === '/notice/manyifu' || to.name === 'webHome') {
      // 在免登录名单，直接进入
      next()
    } else {
      // next()
      // check login user.roles is null 判断登录角色的权限信息 roles
      // console.log(!store.getters.roles || store.getters.roles !== false)
      if (!store.getters.roles) {
      //   // request login userInfo 请求获取角色信息
        store.dispatch('GetInfo').then(res => {
            // console.log('res', res)
            // 根据用户权限信息生成可访问的路由表
            // console.log(storage.get('UserInfo').type)
            store.dispatch('GenerateRoutes', res).then(() => {
              // console.log('切换')
              // 动态添加可访问路由表
              // VueRouter@3.5.0+ New API
              // console.log('success')
              resetRouter() // 重置路由 防止退出重新登录或者 token 过期后页面未刷新，导致的路由重复添加
              if (!storage.get('ACCOUNT')) {
                // console.log('a', to.path)
                router.push({ path: '/' })
              }
              store.getters.addRouters.forEach(r => {
                router.addRoute(r)
              })
              // sessionStorage.removeItem(`store-${from.path}`)
              // window.history.replaceState(null, null, store.getters.addRouters)
              // router.replace({ path: '/' })
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              if (storage.get('ACCOUNT')) {
                // console.log('c', to.path)
                const redirect = decodeURIComponent(from.query.redirect || to.path)
                if (to.path === redirect) {
                  // set the replace: true so the navigation will not leave a history record
                  next({ ...to, replace: true })
                } else {
                  // 跳转到目的路由
                  next({ path: redirect })
                }
              }
            }).catch(err => {
                console.log(err)
              })
            }).catch(() => {
                notification.error({
                  message: '错误',
                  description: '请求用户信息失败，请重试'
                })
                // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
                store.dispatch('Logout').then(() => {
                next({ path: loginRoutePath, query: { redirect: to.fullPath } })
              })
            })
      } else {
        // console.log('b', to.path)
        // if (storage.get('ACCOUNT')) {
        //   if (to.path.includes('type')) {
        //     console.log('b')
        //     next({ path: defaultRoutePath })
        //   } else {
        //     next()
        //   }
        // } else {
        //   next()
        // }
        next()
      }
    }
  } else {
    localStorage.clear()
    // console.log(to)
    if (allowList.includes(to.name) || to.path === '/alipay/callback') {
      // 在免登录名单，直接进入
      next()
    } else {
      // 拦截进入登录页
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar 网页进度条加载完成
})
