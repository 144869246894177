import { openRouterMap } from '@/config/router.config'
import { merchantRouterMap } from '@/config/merchantRouter.config'
import { manageRouterMap } from '@/config/manageRouter.config'
import { selectRouterMap, constantRouterMap } from '@/config/baseRouter.config'
// import { constantRouterMap } from '@/config/baseRouter.config'
// import cloneDeep from 'lodash.clonedeep'

/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
// function hasPermission (permission, route) {
//   if (route.meta && route.meta.permission) {
//     console.log('hasPermission', permission)
//     if (permission === undefined) {
//       return false
//     }
//     let flag = false
//     for (let i = 0, len = permission.length; i < len; i++) {
//       flag = route.meta.permission.includes(permission[i])
//       if (flag) {
//         return true
//       }
//     }
//     return false
//   }
//   return true
// }

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}

/**
 * 根据确实权限判断路由权限的数组
 * routerMap @全局路由
 */

// function filterAsyncRouter (routerMap, role) {
//   const accessedRouters = routerMap.filter(route => {
//     if (hasPermission(role.permissionList, route)) {
//       if (route.children && route.children.length) {
//         // 递归
//         route.children = filterAsyncRouter(route.children, role)
//       }
//       return true
//     }
//     return false
//   })
//   return accessedRouters
// }

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    // 切换路由
    GenerateRoutes ({ commit }, data) {
      // console.log(data)
      return new Promise(resolve => {
        // const accessedRouters = !data ? selectRouterMap : createRouterMap
        const accessedRouters = data === 'merchant' ? merchantRouterMap : data === 'open' ? openRouterMap : data === 'manage' ? manageRouterMap : selectRouterMap
        // const accessedRouters = data === 'merchant' ? merchantRouterMap : data === 'open' ? openRouterMap : data === 'manage' ? manageRouterMap : ''
        // const { role } = data
        // const routerMap = cloneDeep(asyncRouterMap)
        // 过滤器，将路由中匹配的路由过滤出来并保存到vuex
        // const accessedRouters = filterAsyncRouter(routerMap, role)
        commit('SET_ROUTERS', accessedRouters)
        // console.log('router', accessedRouters)
        resolve()
      })
    }
    // GenerateRoutes2 ({ commit }) {
    //   return new Promise(resolve => {
    //     commit('SET_ROUTERS', asyncRouterMap)
    //     resolve()
    //   })
    // }
  }
}

export default permission
