<template>
  <div class="QRcode" :style="{'width':width,'height':height}">
    <canvas :id="canvasId" :style="{'width':width,'height':height}" style="margin: 0 auto;"></canvas>
  </div>
</template>

<script>
import QRcode from 'qrcode'
export default {
  // name: "QRcode",
  props: {
    content: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '300'
    },
    height: {
      type: String,
      default: '300'
    },
    load: {
      type: Boolean,
      default: true
    },
    view: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      canvasId: ''
    }
  },
  created () {
    this.canvasId = this.getUUID()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      const width = this.width
      const height = this.height
        QRcode.toCanvas(
          document.getElementById(this.canvasId),
          this.content,
          { width, height, toSJISFunc: QRcode.toSJIS }
      )
    },
    getUUID () {
      const d = new Date().getTime()
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (d + Math.random() * 16) % 16 | 0
          return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
        }
      )
      return uuid
    }
  },
  watch: {
    content (val) {
      this.init()
    }
  }
}
</script>

<style lang="less" scoped>
.QRCode {
  display: inline-block;
  position: relative;
  overflow: hidden;
  .QQMode {
    position: absolute;
    left: 0;
    bottom: 100%;
    right: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.45);
    transition: all 200ms ease-in-out;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    font-weight: bolder;
    box-sizing: border-box;
    padding: 10px;
  }
}
.QRCode:hover .QQMode {
  bottom: 0;
  height: 100%;
}
</style>
