/*
 * @description:
 * @param :
 * @return:
 */
import request from '@/utils/request'
import { message } from 'ant-design-vue'

const userApi = {
  Login: '/LoginByPassword', // 账号密码登录
  LoginByAuthCodeBase: '/LoginByAuthCodeBase', // 支付宝授权登录
  accountList: '/AgentAccountList', // 用户账号管理
  getPrePayUser: '/getPrePayUser', // 用户信息
  Logout: '/auth/logout'
}

/**
 * @param parameter
 * @returns {*}
 */
// 登录
export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

// 支付宝授权登录
export function LoginByAuthCodeBase (parameter) {
  return request({
    url: userApi.LoginByAuthCodeBase,
    method: 'post',
    data: parameter
  })
}

// 用户账号管理
export function getAccountList (parameter) {
  return request({
    url: userApi.accountList,
    method: 'post',
    data: parameter
  })
}

export function getPrePayUser (parameter) {
  return request({
    url: userApi.getPrePayUser,
    method: 'post',
    data: parameter
  })
}

// 上传图片 获取OSS
export function uploadToServe () {
  return request({
    url: '/GetAliOSSAccessFormUploadManyifuBySign',
    method: 'post',
    requestBase: 'VUE_APP_API_BASE_URL',
    // requestBase: 'VUE_APP_API_BASE_URL_OSS',
    data: { catalogue: 'manyifu' }
  })
}
// 上传阿里云
export function upFileToOSS (file) {
  const fileInfo = file
  console.log(file, 'fileInfo')
  return new Promise((resolve, reject) => {
    uploadToServe()
      .then((res) => {
        console.log(res, 'upfileToOSS')
        if (res.code === 200) {
          var curresult = res.body
          var upurl = curresult.Url
          var policy = curresult.Policy
          var OSSAccessKeyId = curresult.OSSAccessKeyId
          var key = curresult.Key
          var signature = curresult.Signature
          const fileUrl = key
          const host = upurl
          const fmData = new FormData()
          fmData.append('host', host)
          fmData.append('key', key)
          fmData.append('policy', policy)
          fmData.append('OSSAccessKeyId', OSSAccessKeyId)
          fmData.append('success_action_status', 200)
          // fmData.append('callback', res.callback)
          fmData.append('signature', signature)
          fmData.append('file', fileInfo)
          request({
            method: 'post',
            // url: '/wsp',
            requestBase: 'VUE_APP_API_BASE_URL_OSS_T',
            data: fmData
          }).then(() => {
            resolve({
              errno: 0, // 注意：值是数字，不能是字符串
              data: {
                  url: host + '/' + fileUrl, // 图片 src ，必须
                  alt: fileInfo.name, // 图片描述文字，非必须
                  href: '' // 图片的链接，非必须
              }
            })
          }).catch(() => {
            message.error('上传失败')
          })
        } else {
          message.error(res.msg || 'err')
        }
      })
      // eslint-disable-next-line
      .catch((err) => {
        console.log('uperr', err)
        // reject()
        // eslint-disable-next-line
        reject({
          errno: 1,
          message: err || 'err'
        })
        message.error(err)
      })
  })
}
