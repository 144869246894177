// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'
// import { bxAnaalyse } from '@/core/icons'

// const RouteView = {
//   name: 'RouteView',
//   render: h => h('router-view')
// }

/**
 * 基础路由 登录、注册
 * @type { *[] }
 */
export const constantRouterMap = [
  // web首页
  {
    path: '/index',
    name: 'webHome',
    component: () => import('@/views/index/index')
  },
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },
  // 微信公众号跳转引导
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/user/Account'),
    meta: { ignoreAuth: true },
    props: route => ({ authCode: route.query.auth_code, appId: route.query.app_id, source: route.query.source, scope: route.query.scope }) // 将URL参数传递给组件
  },
  // 微信公众号回跳解析
  {
    path: '/weixin/GetOpenId',
    name: 'Account',
    component: () => import('@/views/user/GetOpenId'),
    meta: { ignoreAuth: true },
    props: route => ({ url: route.query.return_url, appId: route.query.app_id, code: route.query.code }) // 将URL参数传递给组件
  },
  // 支付宝授权登录
  {
    path: '/alipay/callback',
    name: 'AlipayCallback',
    component: () => import('@/views/user/AlipayCallback'),
    meta: { ignoreAuth: true },
    props: route => ({ authCode: route.query.auth_code, appId: route.query.app_id, source: route.query.source, scope: route.query.scope }) // 将URL参数传递给组件
  },
  // 引导前往小程序
  {
    path: '/notice/kaitongtuangou',
    name: 'KaiTongTuanGou',
    component: () => import('@/views/index/kaitongtuangou.vue')
  },
  {
    path: '/notice/manyifu',
    name: 'ManYiFu',
    component: () => import('@/views/index/manyifu.vue')
  },
  {
    path: '/editor',
    name: 'Editor',
    component: () => import('@/views/other/editor/editor.vue')
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
/**
 * 选择账号路由
 * @type { *[] }
 */
export const selectRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/type',
    children: [
      {
        path: '/type',
        name: 'Home',
        // component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
        component: () => import('@/views/user/typeSelect'),
        meta: { title: '账号选择', icon: 'select', keepAlive: true, permission: ['dashboard'] },
        hideChildrenInMenu: true,
        children: [
          {
            path: '/type/select',
            name: 'Select',
            component: () => import('@/views/user/Select'),
            meta: { title: '', keepAlive: true, permission: ['form'] }
          }
        ]
      },
      // 账号个人中心
      {
        path: '/person',
        component: () => import('@/views/person/center'),
        // redirect: '/person/center',
        name: 'center',
        meta: { title: '我的账户', icon: 'user', keepAlive: true, permission: ['user'] }
      }
    ]
  },
  {
    path: '*',
    redirect: '/home'
  }
]
