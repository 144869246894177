<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <div class="user-layout-lang" v-if="this.$route.path === '/user/select'">
        <span v-if="currentUser && currentUser.name" class="ant-pro-account-avatar">
          <a-avatar size="small" :src="currentUser.avatar?currentUser.avatar:'logo_logo_mini.png'" class="antd-pro-global-header-index-avatar" />
          <span>{{ currentUser.name }}</span>
          <a style="margin: 0 10px;" @click="handleLogout">注销账号</a>
        </span>
        <span v-else>
          <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
        </span>
      </div>
      <div class="user-layout-content">
        <!-- <div class="top">
          <div class="header">
            <a href="/">
              <img src="~@/assets/logo.svg" class="logo" alt="logo">
              <span class="title">Ant Design</span>
            </a>
          </div>
          <div class="desc">
            {{ $t('layouts.userLayout.title') }}
          </div>
        </div> -->

        <router-view />
        <!-- <global-footer /> -->
        <div class="footer">
          <div class="links">
            <a href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91420100688822140E-SAIC_SHOW_10000091420100688822140E1668491243414&signData=MEUCIQDriD4M9uFYg/q4Cf5kP77LI5lMDz5vAgf/6zNZibNjnwIgXpNCIe4bYXfrkE3dbyH5Eg5oE9u1KuXcreQH2vpi5X8=" target="_blank">营业执照</a>
            <a href="https://www.manyifu.com/about/privacy-policy" target="_blank">隐私政策</a>
            <div style="margin-top:8px">
              <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021014432号-1</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502004502" target="_blank">鄂公网安备 42018502004502号</a>
            </div>
          </div>
          <div class="copyright">
            <a href="http://www.manyifu.com" target="_blank">2020 - {{ T }} © 满意付 MANYIFU.COM</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'
import { deviceMixin } from '@/store/device-mixin'
import GlobalFooter from '@/components/GlobalFooter'
import { Modal } from 'ant-design-vue'
export default {
  name: 'UserLayout',
  components: {
    GlobalFooter
  },
  data () {
    return {
      currentUser: {}
    }
  },
  methods: {
    handleLogout (e) {
      Modal.confirm({
        title: '提示',
        content: this.$t('layouts.usermenu.dialog.content'),
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        },
        onCancel () {}
      })
    }
  },
  computed: {
    T () {
      const time = new Date()
      return time.getFullYear()
    }
  },
  mixins: [deviceMixin],
  mounted () {
    const userInfo = storage.get('UserInfo') || ''
    // console.log(userInfo)
    setTimeout(() => {
      this.currentUser = {
        name: userInfo.UserName,
        avatar: userInfo.Avatar
      }
    }, 1500)
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background-size: 100%;
    //padding: 50px 0 84px;
    position: relative;
    .user-layout-content {
      padding: 32px 0 24px;
    }
    .user-layout-lang {
      width: 100%;
      height: 40px;
      line-height: 44px;
      text-align: right;
      .select-lang-trigger {
        cursor: pointer;
        padding: 12px;
        margin-right: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        vertical-align: middle;
      }
    }
    .footer {
        // position: absolute;
        width: 100%;
        // bottom: 0;
        padding: 0 16px;
        margin: 28px 0;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }

      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.45);
        // -webkit-transition: all 0.3s;
        transition: all 0.3s;
        &:hover {
          color: rgba(0, 0, 0, 0.5);
        }
      }

  }
}
</style>
