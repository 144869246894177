import axios from 'axios'

const isNewVersion = () => {
  // console.log('aa')
  const url = `//${window.location.host}/version.json?t=${new Date().getTime()}`
  axios.get(url).then(res => {
    // console.log(res)
    if (res.status === 200) {
      const vueVersion = res.data.version
      const localVueVersion = localStorage.getItem('vueVersion')
      if (localVueVersion && localVueVersion !== vueVersion) {
        localStorage.setItem('vueVersion', vueVersion)
        window.location.reload()
      } else {
        localStorage.setItem('vueVersion', vueVersion)
        // window.location.reload()
      }
    }
  })
}

export default {
  isNewVersion
}
