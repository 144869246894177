
/*
 * @description:
 * @param :
 * @return:
 */
// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import request from '@/utils/request'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import QRcode from '@/components/QRcode'
// import JsEncrypt from 'jsencrypt'
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
// 全局时间转换方法
import { formatToBeijing } from '@/utils/util'
Vue.prototype.$formatToBeijing = formatToBeijing

Vue.prototype.$request = request

Vue.config.productionTip = false

// 二维码
Vue.use(QRcode)
// Vue.prototype.$jsEncrypt = JsEncrypt
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// 图片预览插件
Vue.use(Viewer)
Viewer.setDefaults({
  title: false,
  toolbar: true
})
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex, Logo message
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
