import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, LoginByAuthCodeBase } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { sendEncryCode } from '@/utils/util'
storage.addPlugin(expirePlugin)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: '',
    info: storage.get('UserInfo') || {},
    account: storage.get('ACCOUNT') || {},
    openAccount: {}, // 开户操作员账号
    merchantAccount: {}, // 商家操作员账号
    manageAccount: {}, // 运营操作员账号
    params: {} // 请求信息参数
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
      // state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    CLEAR_ACCOUNT: (state) => {
      state.account = ''
      state.merchantAccount = ''
      state.manageAccount = ''
      state.roles = ''
      storage.remove('ACCOUNT')
      storage.remove('merchantAccount')
      storage.remove('manageAccount')
    },
    SET_ACCOUNT: (state, info) => {
      state.account = info
      storage.set('ACCOUNT', info)
    },
    SET_MERCHANTACCOUNT: (state, info) => {
      state.merchantAccount = info
      storage.set('merchantAccount', info)
    },
    SET_MANAGEACCOUNT: (state, info) => {
      state.manageAccount = info
      storage.set('manageAccount', info)
    }
  },

  actions: {
    // 账号登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          // console.log('response', response)
          if (response.code === 200) {
            const result = response.body
            // 解密
            result.UserName = sendEncryCode(result.UserName)
            result.AlipayUid = sendEncryCode(result.AlipayUid)
            result.UniqueId = sendEncryCode(result.UniqueId)
            result.uid = sendEncryCode(result.uid)
            result.token = sendEncryCode(result.token)
            // storage.clear()
            // storage.remove('ACCOUNT')
            commit('SET_INFO', result)
            // 保存用户信息到本地
            storage.set('UserInfo', result)
            // this.Select()
            // 保存token
            storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            resolve()
          } else {
            reject(response)
          }
      }).catch(error => {
        console.log(error)
        reject(error)
        })
      })
    },
    // 支付宝授权登录
    AlipayLogin ({ commit }, param) {
      return new Promise((resolve, reject) => {
        LoginByAuthCodeBase(param).then(response => {
          // console.log('response', response)
          if (response.code === 200) {
            const result = response.body
            // 解密
            result.UserName = sendEncryCode(result.UserName)
            result.AlipayUid = sendEncryCode(result.AlipayUid)
            result.UniqueId = sendEncryCode(result.UniqueId)
            result.uid = sendEncryCode(result.uid)
            result.token = sendEncryCode(result.token)
            // storage.clear()
            // storage.remove('ACCOUNT')
            commit('SET_INFO', result)
            // 保存用户信息到本地
            storage.set('UserInfo', result)
            // this.Select()
            // 保存token
            storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            resolve()
          } else {
            reject(response)
          }
      }).catch(error => {
        console.log(error)
        reject(error)
        })
      })
    },

    // 选择档案
    selectAccount ({ commit }, item, type) {
      // 清除前面的缓存
      commit('CLEAR_ACCOUNT')
      // if (type === 'merchant') {
      //   commit('SET_MERCHANTACCOUNT', item)
      // }
      // if (type === 'open') {
        commit('SET_ACCOUNT', item)
      // }
      // if (type === 'manage') {
      //   commit('SET_MANAGEACCOUNT', item)
      // }
      commit('SET_ROLES', type)
    },
    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise(resolve => {
        const roles = storage.get('ACCOUNT') ? storage.get('roles') || 'select' : 'select'
        // const roles = storage.get('roles') || 'select'
        commit('SET_ROLES', roles)
        resolve(roles)
      }).catch(error => {
          console.log(error)
        })
    },
    // 重选账号
    Select ({ commit }) {
        commit('CLEAR_ACCOUNT')
        // commit('SET_ROLES', '')
        storage.remove('roles')
    },

    // 登出
    Logout ({ commit }) {
        commit('CLEAR_ACCOUNT')
        commit('SET_TOKEN', '')
        commit('SET_INFO', '')
        // commit('SET_ROLES', '')
        storage.remove(ACCESS_TOKEN)
        storage.remove('UserInfo')
        storage.remove('roles')
    }

  }
}

export default user
