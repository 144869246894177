import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

// 文本过滤
Vue.filter('text', function (value) {
  if (value && value !== null) return value
  else return '--'
})

// 金额数字截取小数点两位并在小数点前补0（.00)
Vue.filter('toFixed', function (value) {
  if (value === undefined || value === null) {
    return ''
  }
  const val = (+value).toFixed(2)
  return val + '元'
})

// 价格添加￥符号
Vue.filter('price', function (value) {
  return `￥${value}`
})

// #FF7A16  warning
// #FF4D4F  error
// #73D13D  success
// #989898  default
// #1c84c6  processing
// #f8ac59  warning
// #FFEDE0
// #FFECEC
// #E6F7E9
// #F6F6F6

// 身份认证
Vue.filter('AuthenticationStatus', function (value) {
  return value === '0' ? '未核验' : value === '1' ? '已通过' : value === '2' ? '待核验' : value === '3' ? '已驳回' : '待核验'
})
Vue.filter('AuthenticationType', function (value) {
  return value === '0' ? '#FF7A16' : value === '1' ? '#1c84c6' : value === '2' ? '#595959' : value === '3' ? '#fd1936' : '#595959'
})
//                                            服务卡
// 服务卡状态
Vue.filter('CardStatus', function (value) {
  return value === '0' ? '待开通' : value === '1' ? '已开通' : value === '2' ? '已完成' : value === '3' ? '已退卡' : value === '4' ? '已暂停' : '--'
})
Vue.filter('CardType', function (value) {
  return value === '0' ? '#FF7A16' : value === '1' ? '#1c84c6' : value === '2' ? '#73D13D' : value === '3' ? '#595959' : value === '4' ? '#fd1936' : ''
})
// 付款方式
Vue.filter('CardPayType', function (value) {
  return value === 'CREDIT_AUTH' ? '免押阶段付' : value === 'NORMAL_AUTH' ? '冻结免预付' : value === 'WITHHOLDING' ? '无忧阶段付' : value === 'CREDIT_WITHHOLDING' ? '信用阶段付' : value === 'ANTCHAIN' ? '契约阶段付' : '--'
})

//                                            消费合约
// 消费合约状态
Vue.filter('PrePayStatus', function (value) {
  return value === '0' ? '待商家填写（未生效）' : value === '1' ? '待客户确认（未生效）' : value === '2' ? '已生效' : value === '3' ? '客户已拒绝（未生效）' : value === '4' ? '商家已取消（已失效）' : value === '5' ? '已无效' : value === '6' ? '已完成' : '--'
})
Vue.filter('PrePayType', function (status) {
  return status === '0' || status === '1' ? '#FF7A16' : status === '2' ? '#73D13D' : status === '4' || status === '5' ? '#595959' : status === '3' ? '#fd1936' : status === '6' ? '#1c84c6' : ''
})
// 覆约状态
Vue.filter('DelayStatus', function (value) {
  return value === '0' ? '正常' : value === '1' ? '有违约' : value === '2' ? '已履约' : '--'
})
Vue.filter('DelayType', function (value) {
  return value === '0' ? '#1c84c6' : value === '1' ? '#FF4D4F' : value === '2' ? '#73D13D' : ''
})

//                                            付款计划
// 支付状态
Vue.filter('PayStatus', function (value) {
  return value === '0' ? '待支付' : value === '1' ? '支付中' : value === '2' ? '已支付' : value === '3' ? '已完成' : value === '4' ? '已无效' : '--'
})
Vue.filter('PayType', function (value) {
  return value === '0' ? '#989898' : value === '1' ? '#FF7A16' : value === '2' ? '#73D13D' : value === '3' ? '#1c84c6' : value === '4' ? '#FF4D4F' : '--'
})

// 确认状态
Vue.filter('PayTaskStatus', function (value) {
  return value === '1' ? '待客户确认' : value === '2' ? '已生效' : value === '3' ? '客户已拒绝' : value === '4' ? '商家已取消' : value === '5' ? '已无效' : '--'
})
Vue.filter('PayTaskType', function (value) {
  return value === '1' ? '#FF7A16' : value === '2' ? '#73D13D' : value === '3' ? '#FF4D4F' : value === '4' ? '#FF4D4F' : value === '5' ? '#989898' : '--'
})
// 自动付款状态
Vue.filter('PauseStatus', function (value) {
  return value === '0' ? '已开启' : value === '1' ? '已暂停（消费者取消代扣协议）' : value === '2' ? '已冻结（消费者投诉或商家主动冻结）' : '--'
})
Vue.filter('PauseType', function (value) {
  return value === '0' ? '#73D13D' : value === '1' ? '#FF4D4F' : value === '2' ? '#989898' : '--'
})

//                                             付款单
// 支付状态
Vue.filter('PaymentStatus', function (value) {
  return value === '0' ? '待支付' : value === '1' ? '支付中' : value === '2' ? '已支付' : value === '3' ? '已完成' : value === '4' ? '已关闭' : value === '5' ? '已取消（异常无效）' : '其他'
})
Vue.filter('PaymentType', function (value) {
  return value === '0' ? '#989898' : value === '1' ? '#FF7A16' : value === '2' ? '#73D13D' : value === '3' ? '#1c84c6' : value === '4' || value === '4' ? '#FF4D4F' : '--'
})
// 支付方式
Vue.filter('PaymentWay', function (value) {
  return value === 'offline' ? '线下付款' : value === 'alipayminiapp' ? '在线付款' : value === 'alipayprepay' ? '免押/冻结阶段付' : value === 'alipaywithholding' ? '无忧阶段付' : value === 'alipaycreditpay' ? '见证阶段付S' : value === 'alipayzhimapay' ? '见证阶段付P' : value === 'alipayantchain' ? '契约阶段付' : '其他方式'
})
Vue.filter('PaymentWayColor', function (value) {
  return value === 'offline' ? '#73D13D' : value === 'alipayminiapp' | 'alipayprepay' | 'alipaywithholding' | 'alipaycreditpay' | 'alipayzhimapay' | 'alipayantchain' ? '#1c84c6' : '#989898'
})

// 覆约状态
Vue.filter('FaithStatus', function (value) {
  return value === 'COMPLETE' ? '已履约' : value === 'VIOLATED' ? '已违约' : value === 'CLOSED' ? '履约已取消' : value === 'null' ? '未报送' : '未报送'
})
Vue.filter('FaithType', function (value) {
  return value === 'COMPLETE' ? '#73D13D' : value === 'VIOLATED' ? '#FF4D4F' : value === 'CLOSED' ? '#989898' : value === 'null' ? '#FF7A16' : '#FF7A16'
})

//                                            退款申请单
// 申请单状态
Vue.filter('ApplyStatus', function (value) {
  return value === '0' ? '待审批' : value === '1' ? '已批准' : value === '2' ? '已拒绝' : value === '3' ? '已撤销' : '--'
})
Vue.filter('ApplyType', function (value) {
  return value === '0' ? '#FF7A16' : value === '1' ? '#73D13D' : value === '2' ? '#FF4D4F' : '#989898'
})
// 申请人类型
Vue.filter('ApplierType', function (value) {
  return value === 'customer' ? '消费者' : value === 'operator' ? '商家操作员' : value === 'admin' ? '平台管理员' : '--'
})
Vue.filter('ApplierStatus', function (value) {
  return value === 'customer' ? '#73D13D' : value === 'operator' ? '#FF4D4F' : value === 'admin' ? '#FF7A16' : '#989898'
})

// 门店绑定状态
Vue.filter('statusMD', function (value) {
  return value === '0' ? '未绑定' : value === '1' ? '已绑定' : value === '2' ? '已删除' : '--'
})
Vue.filter('statusMDType', function (value) {
  return value === '0' ? '#989898' : value === '1' ? '#73D13D' : value === '2' ? '#FF7A16' : ''
})
// 口碑展示状态
Vue.filter('statusKB', function (value) {
  return value === '0' ? '已展示' : value === '1' ? '未展示' : '--'
})
Vue.filter('statusKBType', function (value) {
  return value === '0' ? '#1c84c6' : value === '1' ? '#FF7A16' : ''
})
// 经营状态
Vue.filter('statusJY', function (value) {
  return value === '0' ? '正常' : value === '1' ? '无效' : '--'
})
Vue.filter('statusJYType', function (value) {
  return value === '0' ? '#1c84c6' : value === '1' ? '#FF4D4F' : ''
})

// 账号
Vue.filter('Role', function (value) {
  return value === 'admin' ? '管理员' : value === 'manager' ? '店长' : value === 'finance' ? '财务' : value === 'marketing' ? '运营' : value === 'salesman' ? '销售' : value === 'test' ? '体验' : value === 'agent' ? '开户顾问' : '--'
})

// 其他
Vue.filter('statusOpenAccount', function (value) {
  return value === '0' ? '待审核' : value === '1' ? '已批准' : value === '2' ? '已暂停' : value === '3' ? '已无效' : '--'
})
Vue.filter('detailAccount', function (value) {
  return value === '0' ? '待审核' : value === '1' ? '已初审' : value === '2' ? '已开通' : value === '3' ? '已无效' : value === '4' ? '已禁用' : '--'
})
Vue.filter('ServiceStatus', function (value) {
  return value === '0' ? '未分配' : value === '1' ? '待跟进' : value === '2' ? '跟进中' : value === '3' ? '已完成' : value === '4' ? '已终止' : '--'
})

// 关联服务卡状态
Vue.filter('PrePayOrderStatus', function (value) {
  return value === '0' ? '待开通（待授权）' : value === '1' ? '已开通（已授权）' : value === '2' ? '已完成' : value === '3' ? '已退卡（已关闭）' : value === '4' ? '已暂停（未授权）' : '--'
})
// 预授权类型
Vue.filter('PreAuthType', function (value) {
  return value === 'CREDIT_AUTH' ? '信用免预付' : value === 'NORMAL_AUTH' ? '冻结免预付' : value === 'WITHHOLDING' ? '无忧阶段付' : value === 'CREDIT_WITHHOLDING' ? '信用阶段付' : value === 'ANTCHAIN' ? '蚂蚁链代扣' : ''
})
// 支付渠道
Vue.filter('PayWay', function (value) {
  return value === 'alipayprepay' ? '支付宝预授权' : value === 'alipayminiapp' ? '当面付，在线付' : value === 'alipayapp' ? '支付宝商家APP支付' : value === 'alipaywap' ? '支付宝商家网页支付' : value === 'alipaywithholding' ? '支付宝免密代扣' : value === 'alipaycreditpay' ? '支付宝芝麻信用代扣' : value === 'alipayzhimapay' ? '支付宝芝麻先享代扣' : ''
})
// 阶段付阶段订单状态
Vue.filter('PayWay', function (value) {
  return value === 'UNCREATED' ? '未生成订单' : value === ' ORDERING' ? '下单中' : value === 'ORDERED' ? '已下单' : value === 'PAYING' ? '支付中' : value === 'PAID' ? '已支付' : value === 'PAY_FAILED' ? '支付失败' : value === 'CANCEL' ? '已取消' : value === 'REFUNDED' ? '已退款' : value === 'PAUSED' ? '已暂停' : '--'
})
// 证件类型
Vue.filter('CertType', function (value) {
  return value === '0' ? '身份证' : value === '1' ? '护照' : value === '2' ? '军官证' : value === '3' ? '士兵证' : value === '4' ? '户口本' : '证件'
})
// 账户类型
Vue.filter('UserType', function (value) {
  return value === '0' ? '未知账户' : value === '1' ? '公司账户' : value === '2' ? '个人账户' : value === '3' ? '个体工商账户' : '--'
})
// 企业类型
Vue.filter('MerchantType', function (value) {
  return value === '01' ? '企业' : value === '02' ? '事业单位' : value === '03' ? '民办非企业组织' : value === '04' ? '社会团体'
  : value === '05' ? '党政及国家机关' : value === '06' ? '个人商户' : value === '07' ? '个体工商户' : '--'
})
// 行业类型
Vue.filter('LegalVerifyType', function (value) {
  return value === '0' ? '教育培训_职业培训' : value === '1' ? '教育培训_素质教育_艺术培训' : value === '2' ? '教育培训_素质教育_其他素质培训'
  : value === '3' ? '教育培训_素质教育_语言培训' : value === '4' ? '教育培训_培训机构_其他培训机构' : value === '5' ? '教育培训_培训机构_亲子早教'
  : value === '6' ? '教育培训_培训机构_课外辅导' : value === '7' ? '教育培训_驾考培训_驾考相关服务' : value === '8' ? '教育培训_驾考培训_驾校'
  : value === '9' ? '教育培训_教育信息服务_综合教育信息服务' : value === '10' ? '教育培训_教育信息服务_出国教育咨询' : value === '11' ? '生活服务_休闲_足疗按摩'
  : value === '12' ? '生活服务_休闲_洗浴汗蒸' : value === '13' ? '生活服务_休闲_游戏玩乐' : value === '14' ? '生活服务_休闲_量贩式KTV'
  : value === '15' ? '生活服务_休闲_休闲娱乐平台' : value === '16' ? '运动健身_丽人_舞蹈' : value === '17' ? '生活服务_丽人_美容/美发'
  : value === '18' ? '生活服务_丽人_美甲/美瞳' : value === '19' ? '生活服务_丽人_纹身' : value === '20' ? '运动健身_丽人_综合场馆服务'
  : value === '21' ? '运动健身_丽人_瑜伽' : value === '22' ? '生活服务_丽人_祛痘' : value === '23' ? '生活服务_丽人_纤体瘦身'
  : value === '24' ? '生活服务_宠物_宠物服务' : value === '25' ? '汽车_维修保养_汽车喷漆店' : value === '26' ? '汽车_维修保养_洗车'
  : value === '27' ? '汽车_维修保养_轮胎翻新、维修' : value === '28' ? '汽车_维修保养_拖车服务' : value === '29' ? '汽车_维修保养_汽车维修、保养好、美容装饰'
  : value === '30' ? '房产_不动产代理_租房' : '--'
})

// 签约状态
const statusMap = {
  0: {
    color: '#989898',
    text: '未签约'
  },
  1: {
    color: '#1c84c6',
    text: '已签约'
  },
  2: {
    color: '#73D13D',
    text: '审核中'
  },
  3: {
    color: '#FF7A16',
    text: '待商户确认协议'
  },
  4: {
    color: '#FF4D4F',
    text: '审核未通过'
  }
}
Vue.filter('statusMapType', function (value) {
  return statusMap[value].color
})
Vue.filter('statusMap', function (value) {
  return statusMap[value].text
})

  // #FF7A16,#FF4D4F,#73D13D,#989898，#1c84c6，#f8ac59
  // #FFEDE0,#FFECEC,#E6F7E9,#F6F6F6
// 小程序
Vue.filter('statusAppType', function (value) {
  return value === '0' ? '#989898' : value === '1' ? '#FF7A16' : value === '2' ? '#73D13D' : '#FF4D4F'
})
Vue.filter('statusApp', function (value) {
  return value === '0' ? '待提交' : value === '1' ? '已创建' : value === '2' ? '已上架' : value === '3' ? '已下架' : '--'
})
Vue.filter('statusSettleType', function (value) {
  return value === '0' ? '#989898' : value === '1' ? '#1c84c6' : value === '2' ? '#73D13D' : value === '3' ? '#f8ac59' : value === '4' ? '#FF4D4F' : ''
})
// 结算、免密代扣、预授权、当面付、订单中心、内容同步、分期、分账
Vue.filter('statusSettle', function (value) {
  return value === '0' ? '未签约' : value === '1' ? '已签约' : value === '2' ? '审核中' : value === '3' ? '待商户确认' : value === '4' ? '审核未通过' : '--'
})
// 见证（芝麻先享支付签约）
Vue.filter('statusZmPay', function (value) {
  return value === '0' ? '未签约' : value === '1' ? '已生效' : value === '2' ? '审核中' : value === '3' ? '待报送' : value === '4' ? '审核未通过' : '--'
})
