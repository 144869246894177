<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021014432号-1</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502004502" target="_blank">鄂公网安备 42018502004502号</a>
        <a href="https://www.manyifu.com/about/privacy-policy" target="_blank">隐私政策</a>
      </div>
    </template>
    <template v-slot:copyright>
      <a href="http://www.manyifu.com" target="_blank">2020 - {{ T }} © 满意付 MANYIFU.COM</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  },
  computed: {
    T () {
      const time = new Date()
      return time.getFullYear()
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-pro-global-footer{
    margin-top:0;
  }
  a {
    color: rgba(0, 0, 0, 0.45);
    // -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
}
</style>
