// eslint-disable-next-line
import { BasicLayout } from '@/layouts'
// import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

/**
 * 开户顾问路由
 */
export const openRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/open/home',
    children: [
      {
        path: '/open/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '欢迎页面', icon: 'home', keepAlive: true, permission: ['dashboard'] }
      },
      {
        path: '/open/card',
        name: 'Card',
        component: () => import('@/views/open/card/index'),
        meta: { title: '服务卡', icon: 'credit-card', keepAlive: true, permission: ['dashboard'] },
        hideChildrenInMenu: true,
            children: [
              {
                path: '/open/card/detail',
                name: 'CardDetail',
                component: () => import('@/views/open/card/detail'),
                meta: { title: '服务卡信息详情', keepAlive: true, permission: ['form'] }
              }
            ]
      },
      {
        path: '/open/prepay',
        name: 'PrePay',
        component: () => import('@/views/open/prepay/index'),
        meta: { title: '消费合约', icon: 'account-book', keepAlive: true, permission: ['dashboard'] },
        hideChildrenInMenu: true,
            children: [
              {
                path: '/open/prepay/detail',
                name: 'PrePayDetail',
                component: () => import('@/views/open/prepay/PrePayDetail'),
                meta: { title: '消费合约详情', keepAlive: true, permission: ['form'] }
              }
            ]
      },
      {
        path: '/open/pay',
        name: 'Pay',
        component: () => import('@/views/open/pay/index'),
        meta: { title: '付款计划', icon: 'transaction', keepAlive: true, permission: ['dashboard'] },
        hideChildrenInMenu: true,
            children: [
              {
                path: '/open/pay/detail',
                name: 'PayDetail',
                component: () => import('@/views/open/pay/detail'),
                meta: { title: '付款计划详情', keepAlive: true, permission: ['form'] }
              }
            ]
      },
      {
        path: '/open/payment',
        name: 'Payment',
        component: () => import('@/views/open/payment/index'),
        meta: { title: '付款单', icon: 'pay-circle', keepAlive: true, permission: ['dashboard'] }
      },
      {
        path: '/open/userSearch',
        name: 'UserSearch',
        component: () => import('@/views/open/userSearch/index'),
        meta: { title: '用户搜索', icon: 'user', keepAlive: true, permission: ['dashboard'] },
        hideChildrenInMenu: true,
            children: [
              {
                path: '/open/userSearch/Detail',
                name: 'UserDetail',
                component: () => import('@/views/open/userSearch/Detail'),
                meta: { title: '用户信息详情', keepAlive: true, permission: ['form'] }
              }
            ]
      },
      {
        path: '/open/person',
        component: RouteView,
        redirect: '/open/person/center',
        name: 'person',
        meta: { title: '开户档案', icon: 'user', keepAlive: true, permission: ['user'] },
        children: [
          {
            path: '/open/person/center',
            name: 'center',
            component: () => import('@/views/open/person/center'),
            meta: { title: '个人中心', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/open/person/settings',
            name: 'settings',
            component: () => import('@/views/person/settings/Index'),
            meta: { title: '个人设置', hideHeader: true, permission: ['user'] },
            redirect: '/person/settings/basic',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/open/person/settings/basic',
                name: 'BasicSettings',
                component: () => import('@/views/open/person/settings/BasicSetting'),
                meta: { title: '基本设置', hidden: true, permission: ['user'] }
              },
              {
                path: '/open/person/settings/security',
                name: 'SecuritySettings',
                component: () => import('@/views/open/person/settings/Security'),
                meta: {
                  title: '安全设置',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              },
              // {
              //   path: '/person/settings/custom',
              //   name: 'CustomSettings',
              //   component: () => import('@/views/open/person/settings/Custom'),
              //   meta: { title: '个性化', hidden: true, keepAlive: true, permission: ['user'] }
              // },
              {
                path: '/open/person/settings/binding',
                name: 'BindingSettings',
                component: () => import('@/views/open/person/settings/Binding'),
                meta: { title: '账号绑定', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/open/person/settings/notification',
                name: 'NotificationSettings',
                component: () => import('@/views/open/person/settings/Notification'),
                meta: {
                  title: '新消息通知',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              }
            ]
          }
        ]
      }
    ]
  }
]
