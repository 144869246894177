<template>
  <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
    <span class="ant-pro-account-avatar">
      <a-avatar size="small" :src="currentUser.avatar?currentUser.avatar:'/logo.png'" class="antd-pro-global-header-index-avatar" />
      <span>{{ currentUser.name }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          {{ $t('menu.account.center') }}
        </a-menu-item>
        <!-- <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          {{ $t('menu.account.settings') }}
        </a-menu-item> -->
        <a-menu-divider v-if="menu" />
        <a-menu-item key="select" @click="handleSelect">
          <a-icon type="select" />
          重选账号
          <!-- {{ $t('menu.account.logout') }} -->
        </a-menu-item>
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          {{ $t('menu.account.logout') }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-tooltip placement="bottom">
      <template slot="title">
        个人信息加载中（若长时间未显示可能数据丢失，请前往“我的账户-个人中心”页面查看或退出）
      </template>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
    </a-tooltip>
  </span>
</template>

<script>
import { Modal } from 'ant-design-vue'
// import storage from 'store'

export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  created () {
    // this.menu = storage.get('roles') !== 'select' || false
  },
  methods: {
    handleToCenter () {
      this.$router.push({ name: 'center' })
    },
    handleToSettings () {
      this.$router.push({ name: 'settings' })
    },
    handleSelect (e) {
      Modal.confirm({
        title: '提示',
        content: '确定要重新选择绑定档案吗？',
        onOk: () => {
          return this.$store.dispatch('Select').then(() => {
            this.$router.push({ path: '/type' })
            // location.reload()
          })
          // return this.$store.dispatch('Select').then(() => {
          //   this.$router.push({ path: '/type' })
          //   // location.reload()
          // })
        },
        onCancel () {}
      })
    },
    handleLogout (e) {
      Modal.confirm({
        title: '提示',
        content: this.$t('layouts.usermenu.dialog.content'),
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
