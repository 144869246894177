const openAccount = {
  state: {
    OrderId: '' // 开户申请单Id
  },
  mutations: {
    changeOrderId (state, id) {
      state.OrderId = id
    }
    // [TOGGLE_MULTI_TAB]: (state, bool) => {
    //   storage.set(TOGGLE_MULTI_TAB, bool)
    //   state.multiTab = bool
    // }
  },
  actions: {
    // setLang ({ commit }, lang) {
    //   return new Promise((resolve, reject) => {
    //     commit(APP_LANGUAGE, 'zh-CN')
    //     loadLanguageAsync(lang).then(() => {
    //       resolve()
    //     }).catch((e) => {
    //       reject(e)
    //     })
    //   })
    // }
  }
}

export default openAccount
